import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom"

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import { DateTime } from "luxon";

import { useForm, Controller } from "react-hook-form";

function Form() {
    const [courseData, setCourseData] =useState();
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState();
    //const [existingData, setExistingDate] = useState();
    const [multi, setMulti] = useState(false);
    const { id } = useParams()

    useEffect(() => {
        const loadData = async () => {

            const response = await fetch("/api/getproject?id=" + id);
            //console.log(response)
            setLoading(false)
            if (!response.ok)  {
                setResult("loadError") 
                return;
            }
            const data = await response.json()
            
            //console.log(data)
            if (data.name === "JsonWebTokenError") {
                setResult("wrongtoken")
                return;
            } else if (data.length === 0) {
                setResult("notactive")
                return;
            } else if (!data.reportToken) {
                setResult("exists")
            }
            setCourseData(data)

            if (data.future) {
                setResult("future")
                return;
            }

            if ("Lehrkräfte" in data?.audience) {
                console.log("Multi")
                setMulti(true)
            } else if ("Multiplikatoren" in data?.audience) {
                console.log("Multi")
                setMulti(true)
            } else {
                console.log("Default")
            }
        }
        loadData()
    
    }, [id])

    const { control, handleSubmit } = useForm({
        defaultValues: {
            total: null,
            female: null,
            male: null,
            diverse: null,
            supervisors: null,
            primary: null,
            secondary: null,
            special: null,
            others: null
          }
    });

    const onSubmit = async (data) => {
        console.log(data);
        setSubmitted(true)
        const response = await fetch ("/api/setreporting?id=" + courseData.reportToken, {
            method: "POST",
            body: JSON.stringify(data)
        })
        setSubmitted(false)
        if (response.ok) {
            setResult("success")
        } else {
            setResult("SubmitError")
        }
    }

    return (
        <div>
            <Typography variant="h5" gutterBottom>{courseData?.projectName}</Typography>
            { courseData?.startDate && <Typography variant="body1" gutterBottom>{DateTime.fromSQL(courseData?.startDate).toLocaleString(DateTime.DATETIME_MED)} | {courseData?.location} | Eventname: {courseData?.firstEventName}</Typography> }
            { loading && <CircularProgress /> }
            { result === "notactive" && <Alert severity="warning">Kein aktives Event gefunden!</Alert> }
            { result === "exists" && <Alert severity="info">Die Daten wurden bereits übermittelt!</Alert> }
            { result === "wrongtoken" && <Alert severity="error">Falsche Reporting URL!</Alert> }
            { result === "loadError" && <Alert severity="error">Server Fehler</Alert> }            
            { courseData?.reportToken &&
                <Container disableGutters sx={{
                        border: "solid #bdbcb9",
                        borderRadius: 2,
                        padding: 1,
                        my: 2
                    }}>
                    <Typography variant="h6" gutterBottom sx={{color: "#3d3d3d"}}>Anzahl der Teilnehmenden:</Typography>
                    <Controller name="total" control={control} rules={{ required: "Angabe erforderlich", min: {value: 1, message: "Wert muss mind. 1 sein."}}} render={({ field, fieldState: {error} }) =>
                        <TextField disabled={result==="success"} fullWidth id="total" error={!!error}  helperText={error?.message} label="Gesamtanzahl Teilnehmende" variant="outlined" type="number" margin="dense" {...field} />
                    }/>
                    { multi ? 
                        <>
                            <Divider variant="middle">Aufteilung der Teilnehmenden:</Divider>
                            <Controller name="primary" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }}  render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="primary" label="Anzahl Grundschule" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Controller name="secondary" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="secondary" label="Anzahl weiterführende Schule" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Controller name="special" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="special" label="Anzahl Förderschule" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Controller name="others" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="others" label="Anzahl Sonstige" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                        </>
                        :
                        <>        
                            <Divider variant="middle">Aufteilung der Teilnehmenden:</Divider>
                            <Controller name="female" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error} }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="female" label="Anzahl weibliche Teilnehmende" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Controller name="male" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="male" label="Anzahl männliche Teilnehmende" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Controller name="diverse" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="diverse" label="Anzahl diverse Teilnehmende" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                            <Divider variant="middle">Begeleitende Personen:</Divider>
                            <Controller name="supervisors" control={control} rules={{ min: {value: 0, message: "Wert kann nicht negativ sein."} }} render={({ field, fieldState: {error}  }) =>
                                <TextField disabled={result==="success"} error={!!error} helperText={error?.message} fullWidth id="supervisors" label="Anzahl begleitende Personen (Lehrkräfte, Betreuer*innen, Eltern, ...)" variant="outlined" type="number" margin="dense" {...field}/>
                            }/>
                        </>
                    }
                    {/*<Button onClick={handleSubmit(onSubmit)} variant="contained" fullWidth>Absenden</Button>*/}
                    {   !result  ?
                        <LoadingButton loading={submitted} onClick={handleSubmit(onSubmit)} variant="contained" fullWidth sx={{my: 2}}>Absenden</LoadingButton>
                        :
                        <> 
                        {result === "success" && <Alert severity="success">Vielen Dank für die Übermittlung der Daten!</Alert>}
                        {result === "submitError" && <Alert severity="error">Leider ist ein Fehler aufgetreten!</Alert>}
                        { result === "future" && <Alert severity="warning">Event liegt in der Zukunft!</Alert> }
                        </>
                    }
                </Container>
            }
        </div>
    )
}

export default Form
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { Route, Routes } from "react-router-dom"

import Form from './Form';

// import './App.css';

function App() {
  return (
    <Container maxWidth="md" sx={{mt: 2}}>
      <Typography variant="h4" gutterBottom>Tech and Teach Reporting</Typography> 
        <Routes>
          <Route path="/" element={"Fehlende Projekt-ID"} />
          <Route path="/:id" element={<Form />} />
        </Routes>
      <Container disableGutters sx={{mt: 2}}>
        <Typography variant="caption" gutterBottom display="block">
          <a href="https://www.techandteach.de/impressum">Impressum</a> | <a href="https://www.techandteach.de/datenschutzerklaerung">Datenschutzerklärung</a>
        </Typography>
      </Container>
    </Container>
  );
}

export default App;
